
function calcCant (obj) {
  let count = 0
  for (let c in obj) {
    count += obj[c].quantity
  }
  // return count
  return count
}
function total (cart) {
  var totalPrice = 0
  for (const key in cart) {
    totalPrice += (cart[key].price * cart[key].quantity)
  }
  return totalPrice
}

export function addProduct ( { commit }, product) {
  // console.log(product);
	if(localStorage.getItem('cart')){
    const currentCart = JSON.parse(localStorage.getItem('cart'))
    var size = Object.keys(currentCart).length;
    currentCart[size] = product
    
    localStorage.setItem('cart', JSON.stringify(currentCart))
    commit('changeCart', currentCart)
    commit('changeCount', calcCant(currentCart))
    commit('changeTotal', total(currentCart))
	} else {
    const tmp1 = {}
    tmp1[0] = product

    localStorage.setItem('cart', JSON.stringify(tmp1))
    commit('changeCart', tmp1)
    commit('changeCount', calcCant(tmp1))
    commit('changeTotal', total(tmp1))
  }
}

export function updateItem ({ commit }, opt) {
  // console.log(opt);
  const cart = JSON.parse(localStorage.getItem('cart'))
  var tmpProd = cart[opt[0]]
  tmpProd.quantity = (opt[1]=="minus") ? tmpProd.quantity-1 : tmpProd.quantity+1
  
  cart[opt[0]] = tmpProd

  localStorage.setItem('cart', JSON.stringify(cart))
  commit('changeCart', cart)
  commit('changeCount', calcCant(cart))
  commit('changeTotal', total(cart))
}

export function removeItem ({ commit }, opt) {
  const cart = JSON.parse(localStorage.getItem('cart'))
  const tmpNewCart = {}
  const newCart = {}
  for (var [key, value] of Object.entries(cart)) {
    if (opt.toString() !== key) {
      tmpNewCart[key] = value
    }
  }
  let i=0
  for (var [key, value] of Object.entries(tmpNewCart)) {
    newCart[i] = value
    i++
  }

  localStorage.setItem('cart', JSON.stringify(newCart))
  commit('changeCart', newCart)
  commit('changeCount', calcCant(newCart))
  commit('changeTotal', total(newCart))
}

export function removeCart ({ commit }) {
  commit('removeAll')
}
