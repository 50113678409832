function initialCount (obj) {
  let item = JSON.parse(obj)
  let count = 0
  for (let c in item) {
    count += item[c].quantity
  }
  return count
}

function total (cart) {
  let item = JSON.parse(cart)
  var totalPrice = 0
  for (const key in item) {
    totalPrice += (item[key].price * item[key].quantity)
  }
  return totalPrice
}

export default function () {
  return {
    cart: (localStorage.getItem('cart')) ? JSON.parse(localStorage.getItem('cart')) : {},
    count: (localStorage.getItem('cart')) ? initialCount(localStorage.getItem('cart')) : 0,
    total: (localStorage.getItem('cart')) ? total(localStorage.getItem('cart')) : 0
  }
}
