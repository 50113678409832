
export function changeCount (state, n) {
  state.count = n
}
export function changeCart (state, item) {
  state.cart = item
}
export function changeTotal (state, n) {
  state.total = n
}
export function removeAll (state) {
  state.cart = {}
  state.total = 0
  state.count = 0
}