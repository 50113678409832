import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  // scrollBehavior (to, from, savedPosition) {
  //   if (to.name == 'home') {
  //     const position = parseInt(from.query.position, 10)
  //     return { x: 0, y: position }
  //   }
  //   return { x: 0, y: 0 }
  // }
})

router.beforeEach((to, from, next) =>  {
  // console.log(to.name);
  if(to.meta.warning){
    if(sessionStorage.getItem('warning')){
      next()
    } else {
      next({
        name:"warning",
        query: { redirect: to.fullPath }
      })
    }
  } 
  if (to.meta.auth) {
    if (localStorage.getItem('authenticate')) {
      next()
    } else {
      next({
        name: 'login',
        query: { redirect: to.fullPath }
      })
    }
  }
  if (to.name == 'login' || to.name == 'register') {
    if (localStorage.getItem('authenticate')) {
      next({
        name: 'home',
      })
    } else {
      next()
    }
  }
  
  next()
})

export default router
