const state = {
  // assets: 'http://sis-ventas.test/images/products/',
  assets: 'https://ventas.negociospotosi.com/images/products/',
  // assets2: 'http://sis-ventas.test/images/brands/',
  assets2: 'https://ventas.negociospotosi.com/images/brands/',
}

const mutations = {}

const actions = {
  // showLoading () {
  //   Loading.show({
  //     spinnerColor: 'secondary',
  //     spinnerSize: 40,
  //     backgroundColor: 'primary',
  //     message: 'FurciosDrink...',
  //     messageColor: 'white'
  //   })
  // },
  // hideLoading () {
  //   Loading.hide()
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}