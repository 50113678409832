
const routes = [
  {
    path: '/',
    component: () => import('../views/layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'home',
          component: () => import('../views/Home.vue'),
        meta: { warning: true }
      },
      {
        path: 'tienda',
        name: 'shop',
          component: () => import('../views/Shop.vue'),
        meta: { warning: true }
      },
      {
        path: 'carrito',
        name: 'checkout',
          component: () => import('../views/Checkout.vue'),
        meta: { warning: true }
      },
      {
        path: 'finalizar-compra',
        name: 'payment',
          component: () => import('../views/Payment.vue'),
        meta: { warning: true }
      },
      {
        path: 'orden-recibida/:id',
        name: 'orderReceived',
          component: () => import('../views/OrderReceived.vue'),
        meta: { warning: true, auth: true }
      },
      // {
      //   path: '/single/:id',
      //   name: 'single',
      //   component: () => import('..views/SingleProduct.vue'),
      //   meta: { back: true, requireAuth: true }
      // },
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('../views/Register.vue')
      },
    ]
  },
  {
    path: '/advertencia',
    name: 'warning',
    component: () => import('../views/auth/Warning.vue')
  },
  {
    path: '/condiciones',
    name: 'conditions',
    component: () => import('../views/privacy/Conditions.vue')
  },
  {
    path: '/politica-de-privacidad',
    name: 'privacy policies',
    component: () => import('../views/privacy/PrivacyPolicies.vue')
  },
  // {
  //   path: '/forgot-pwd',
  //   name: 'forgot',
  //   component: () => import('../views/auth/ResetPwd.vue')
  // },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    name: 'default',
    component: () => import('../views/404.vue')
  }
]
  
export default routes