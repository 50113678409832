import axios from 'axios';

export function logout ({ commit }) {
  // url api rest
  var auth = JSON.parse(localStorage.getItem('authenticate'))
  const bodyParameters = {
    key: 'logout'
  }
  axios.post('logout', bodyParameters, {
    headers: {
      Authorization: 'Bearer ' + auth.token,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  .then(response => {
    console.log(response)
    if (response.data === 'ok' && localStorage.getItem('authenticate')) {
      commit('addTokenLogin', {
        token: '',
        login: false,
        name: '',
        photo: ''
      })
      localStorage.removeItem('authenticate')
      // Redirect to old url or home
      // this.$router.push('/')
    }
  })
  .catch(error => {
    console.log(error.response)
  })
}